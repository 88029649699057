import axios from "axios";
import { API_URL } from "../../configs/endpoints";
import { getAccessToken, setAccessToken, getRefreshToken, deleteAccessToken, deleteRefreshToken } from "../utils";


const instance = axios.create({
  baseURL: API_URL + "/api/v1",
});

instance.interceptors.request.use(
  (config) => {
    // ** Get token from localStorage
    const accessToken = getAccessToken()

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if error response has status 401, which means token has expired
    if (error.response && error.response.status === 401 && !error.config._retry) {
      // set _retry flag to make sure we don't get into a loop
      error.config._retry = true;

      try {
        // Assuming you have a refreshToken and a refresh endpoint
        // Also assuming you have a utility function getRefreshToken() to get the refresh token from storage
        const refreshToken = getRefreshToken();

        if (!refreshToken) {
          //LogoutNow();
  
          return Promise.reject(error);
        }

        // Make a request to refresh the token
        const headers = {
          'Authorization': 'Bearer ' + refreshToken
        };

        const { data } = await axios.post(API_URL + "/api/v1/refresh-token", {}, { headers: headers });

        // Assuming the new access token is returned in data.accessToken
        setAccessToken(data);

        // Modify the old request to use the new token
        error.config.headers['Authorization'] = `Bearer ${data}`;

        // Resend the request
        return instance.request(error.config);
      } catch (refreshError) {
        // If token refresh also fails, log the user out
        //LogoutNow();
        deleteAccessToken();
        //deleteRefreshToken();

        window.location.href = "/login?sessionExpired=true&next=" + window.location.pathname;


        return Promise.reject(refreshError);
      }
    } else if (error.response && error.response.status === 401) {
        console.log("401")
        console.log(error.response)
        if (error.response.data.code === "XERO_TOKEN_MISSING") {
          window.location.href = API_URL + "/auth/xero?next=" + window.location.pathname + "&accessToken=" + getAccessToken();
        }
    }

    return Promise.reject(error);
  }
);

export default instance;