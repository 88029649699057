import { Grid, Box, Avatar, Typography, CircularProgress, Card, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {styled} from "@mui/material"

const CustomButton = styled("button")(({ theme }) => ({
    border: '1px solid #ACACAC', padding: '3px 6px', fontSize: '12px', 
    fontWeight: 400, backgroundColor: "#fff", borderRadius: '1px',
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer"
    },
  }));

export default function DetailsCard(props) {
    const { data } = props

    const [patient_data, setPatientData] = useState([])
    const { patient_id, case_id } = useParams()

    const get_languages = (langs) => {
        var langs_str = ""
        langs.forEach(element => {
            langs_str += ` ${element.name}` + ","
        });

        return langs_str.slice(0, -1);
    }
    const navigate = useNavigate()

    useEffect(() => {
        if (data) {
            setPatientData([[
                { title: 'Date of Birth', value: data.dob },
                { title: 'Estimated DOB', value: data.dob_text },
                { title: 'Gender', value: data.gender },
                { title: 'Birth Certificate', value: data.has_birth_certificate }
            ],
            [
                { title: 'Height (cm)', value: data.height },
                { title: 'Weight (kg)', value: data.weight },
                { title: 'Religion', value: data.religion },
                { title: 'Primary Region', value: data.region }
            ],
            [
                { title: 'Citizenship', value: data.citizenship },
                { title: 'Medical Classification', value: data.medical_classification },
                { title: 'Languages Spoken', value: get_languages(data.languages) },
                { title: 'Understands English', value: data.understands_english }
            ]])
        }
    }, [data])

    const items = [
        { name: "Classification", value: data?.medical_classification },
        { name: "Region", value: data?.region },
        { name: "Status", value: data?.status },
    ];


    if (!data) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress />
            </Box>
        )
    }




    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <Card sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Avatar alt={data.first_name} src={data.avatar} sx={{ width: 82, height: 82 }} variant="square" />
                            </Box>
                            <Box sx={{ pl: 2, width: '100%' }}>
                                <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: '100%' }}>
                                    <Stack gap={1} direction={"row"}>
                                        <Typography variant="subtitle2" color={"primary"}>{data.romac_id}</Typography> |
                                        <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", lineHeight: 1.2, fontWeight: 'bold' }}>{data.first_name} {data.last_name}
                                        </Typography>
                                    </Stack>

                                    <Stack gap={1} direction={"row"}>
                                        <Link to={`/patients/${patient_id}`} style={{ textDecoration: 'none' }}>
                                        <CustomButton>
                                            View Details
                                        </CustomButton>
                                        </Link>
                                        <Link to={`/patients/${patient_id}/edit`} style={{ textDecoration: 'none' }}>
                                        <CustomButton>
                                            Edit
                                        </CustomButton>
                                        </Link>
                                    </Stack>

                                </Stack>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 0.5 }}>
                                    <Typography variant="caption">
                                        Sex: {data?.gender} | DOB: {data?.dob} | Age: {data?.age}


                                    </Typography>
                                    <Typography variant="caption" color={"primary"}>{data.country}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 600, fontSize: '15px', mb: 1.1 }}>
                                Case {`${patient_id}-${case_id}`}
                            </Typography>
                        </Box>

                        <Stack gap={4} direction="row">
                            {items.map((item, index) => (
                                <Box key={`item-${index}`}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>{item.name}</Typography>
                                    <Typography variant="body1">{item.value || "N/A"}</Typography>
                                </Box>
                            ))}
                        </Stack>

                    </Card>
                </Grid>




                {/* <Grid item md={9}>
                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>DETAILS</Typography>
                    <Grid container spacing={1}>
                        {
                            patient_data.map((group_arr, group_ind) => {
                                return (
                                    <Grid key={`patient-data-g-${group_ind}`} item md={4}>
                                        {
                                            group_arr.map((det, det_index) => (
                                                <Box key={`patient-data-${group_ind}-${det_index}`} sx={{ display: 'flex' }}>
                                                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92, 0.8)", fontWeight: 400, fontSize: '13px', minWidth: '130px' }}>{det.title}:</Typography>
                                                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>{det.value}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <Box sx={{ display: 'flex', mt: 1, gap: 1 }}>
                                <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>Contact number (eg. Whatsapp no.):</Typography>
                                <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>{data.whatsapp_number}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px', mt: 1 }}>Contact Details:</Typography>
                            <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>{
                                data.address
                            }</Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </Box>
    )
}