import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import instance from '../../auth/utils/useJwt';
import { Select, Stack } from '@mui/material';
import AppLayout from '../../components/layout/AppLayout';
import dayjs from 'dayjs';
import MuiDataGrid from "../../components/tables/MUIDataGrid";
import CustomInputLabel from '../../components/forms/CustomInputLabel';
import ActivityColumns from './columns';
import DatePicker from '../map/DatePicker';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function NotificationsPage() {

    const [loading, setLoading] = React.useState(false)

    const [notifications, setNotifications] = React.useState([])
    const [filteredNotifications, setFilteredNotifications] = React.useState([])
    const [filters, setFilters] = React.useState({
        base: "all", fromDate: null, toDate: null
    })
    const [uniqueResource, setUniqueResource] = React.useState([])
    const get_notifications = () => {
        setLoading(true)
        instance.get("/notifications?type=all").then(res => {
            setNotifications(res.data?.notifications || [])
            setFilteredNotifications(res.data?.notifications || [])
            let resources = res.data?.notifications.map(notification => notification.resource)
            let unique = [...new Set(resources)]
            setUniqueResource(unique.map(resource => ({ label: resource, value: resource })))
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    React.useEffect(() => {
        get_notifications()
    }, [])

    React.useEffect(() => {
        const filterByDate = (notifications) => {
            return notifications.filter((notification) => {
                const timestampDate = dayjs(notification.timestamp.split(" ")[0]); // Extract and parse the date part
                const fromDate = filters.fromDate ? dayjs(filters.fromDate, "DD/MM/YYYY") : null;
                const toDate = filters.toDate ? dayjs(filters.toDate, "DD/MM/YYYY") : null;
    
                // Apply the filtering logic
                const isAfterFromDate = fromDate ? timestampDate.isSameOrAfter(fromDate) : true;
                const isBeforeToDate = toDate ? timestampDate.isSameOrBefore(toDate) : true;
    
                return isAfterFromDate && isBeforeToDate;
            });
        };
    
        const applyFilters = () => {
            let filtered = notifications;
    
            if (filters.base !== "all") {
                filtered = filtered.filter(notification => notification.resource === filters.base);
            }
    
            filtered = filterByDate(filtered);
            setFilteredNotifications(filtered);
        };
    
        applyFilters();
    }, [filters, notifications])




    return (
        <AppLayout px={0} apppadding={"0px"}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', py: 1.7, px: 3, backgroundColor: "#fff", mb: 2, borderRadius: 'none' }}>
                <Typography variant="h6">All Activity</Typography>


            </Box>


            <MuiDataGrid density='compact'
                data={filteredNotifications} loading={loading}
                baseFilters={false}
                searchPlaceholder={"Enter @romac.org.au user"}
                searchLabel={"User Filter"}
                filters={
                    <Stack direction="row" spacing={2}>
                        <Box>
                        <CustomInputLabel sx={{
                            fontWeight: 700
                        }}>Resource</CustomInputLabel>

                        <Select size='small' value={filters.base}>
                            {
                                [
                                    { label: 'All Resources', value: 'all' },
                                    ...uniqueResource
                                ].map((option) => (
                                    <MenuItem key={option.value} value={option.value} onClick={() => setFilters({ base: option.value })}>
                                        {option.label}
                                    </MenuItem>
                                ))

                            }
                        </Select>
                        </Box>
                        <Box>
                            <CustomInputLabel sx={{
                                fontWeight: 700
                            }}>From Date</CustomInputLabel>
                            <DatePicker sx={{ width: '180px' }} value={filters.fromDate} onChange={(e) => setFilters({ ...filters, fromDate: e.value })} />
                        </Box>
                        <Box>
                            <CustomInputLabel sx={{
                                fontWeight: 700
                            }}>To Date</CustomInputLabel>
                            <DatePicker sx={{ width: '180px' }} value={filters.toDate} onChange={(e) => setFilters({ ...filters, toDate: e.value })} />
                        </Box>
                    </Stack>}
                columns={ActivityColumns} />
        </AppLayout>
    );
}