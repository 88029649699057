import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    IconButton,
    LinearProgress,
    Tooltip,
    Typography,
} from '@mui/material';

import instance from '../../../auth/utils/useJwt';
import InvoiceCreationBasicForm from './BasicData';
import InvoiceItemsTable from './Items';
import { useSelector } from 'react-redux';
import AppLayout from '../../../components/layout/AppLayout';
import BContainer from '../../../components/BContainer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import BreadCrumbs from '../../../components/BreadCrumbs';
import PatientCaseInvoiceNotes from './InvoiceNotes';
import useSnackbar from '../../../components/Dialogs/SnackBar';
import AuthorizeInvoiceDialog from './components/AuthoriseDialog';
import DeleteInvoice from './components/DeleteDialog';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

export default function PatientCaseInvoiceDetails() {
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    //const { selectedInvoice } = props
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.user)
    const { patient_id, case_id, invoice_id: invId } = useParams()
    const [lastClicked, setLastClicked] = useState(null);
    const [invoice_id, setInvoiceId] = useState(invId)
    const [submitClicked, setSubmitClicked] = useState(false)
    const navigate = useNavigate()
    const [taxAmount, setTaxAmount] = useState(0)
    const [processing, setProcessing] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const location = useLocation();
    const [prev, setPrev] = useState("case")

    const [amountMatches, setAmountMatches] = useState(false);
    const { control, handleSubmit, register, formState: { errors }, setValue, getValues, reset, watch } = useForm({
        InvoiceID: '',
        CurrencyCode: 'AUD',
        InvoiceNumber: '', Contact: '', Date: '', DueDate: '', Reference: '', Status: 'DRAFT',
        LineItems: [{ ItemCode: '', Description: '', Quantity: '', UnitAmount: '' }],
        Attachments: []

    });
    
    const [LineItems, setLineItems] = useState([{ ItemCode: '', Description: '', Quantity: '', UnitAmount: '', TaxType: '' }])

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'LineItems'
    });

    const { fields: attachments, append: appendAttachments, remove: removeAttachments } = useFieldArray({
        control, name: 'Attachments'
    })


    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const nextValue = queryParams.get('refPage');

        // Store the `next` value in local storage
        if (nextValue) {
            setPrev(nextValue);

        }
    }, [location.search]);


    const fetchInvoices = () => {
        instance.get("/xero/invoices").then(res => {
            setInvoices(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchInvoices()
    }, [invId])

    const handleClose = (data) => {
        if (lastClicked === 'saveAndClose' || data === "close" ) {
            if (prev === 'case')
                navigate(`/patients/${patient_id}/case/${case_id}?tab=expenses`)
            else
                navigate(`/pam/expenses`)
            return
        }else if (lastClicked === 'saveOnly') {
            navigate(`/patients/${patient_id}/case/${case_id}/invoice/${data}`)
        }
        // if (data === "close") {
        //     navigate(`/patients/${patient_id}/case/${case_id}?tab=expenses`)
        // }
    };

    const dueDateEmpty = watch("DueDate") === '' || watch("DueDate") === null || watch("DueDate") === undefined

    useEffect(() => {
        if (invoice_id !== "new") {
            setProcessing(true)
            instance.get(`/xero/invoices/${invoice_id}`).then(({ data }) => {

                reset({
                    InvoiceNumber: data.InvoiceNumber,
                    Contact: data.Contact.ContactID,
                    Date: data.Date,
                    CurrencyCode: data.CurrencyCode,
                    DueDate: data.DueDate,
                    Reference: data.Reference,
                    LineItems: data.LineItems,
                    Status: data.Status,
                    Attachments: data.Attachments,
                    meta: data.meta
                })
                setLineItems(data.LineItems)
                setSelectedInvoice(data)
                setProcessing(false)
            }).catch(err => {
                setProcessing(false)
                console.log("Error getting invoice data", err)
            })
            setOpen(true)
        } else {
            reset({
                InvoiceID: '',
                InvoiceNumber: invoiceNumber,
                CurrencyCode: 'AUD',
                Contact: '',
                Date: '',
                DueDate: '',
                Status: 'DRAFT',
                Reference: '',
                LineItems: [{ ItemCode: '', Description: '', Quantity: '', UnitAmount: '', TaxType: '' }],
                Attachments: []
            })
            setLineItems([{ ItemCode: '', Description: '', Quantity: '', UnitAmount: '', TaxType: '' }])
        }
    }, [invoice_id])

    const { data: invoicePaymentData } = useSelector(state => state.payment_details)


    const onSubmit = (data) => {
        // Check if for list of LineItems, if ItemCode, Quantity, UnitAmount is empty, set state setSubmitClicked to true else set to false
        let submitClickedTemp = false
        LineItems.forEach(item => {
            if (item.ItemCode === '' || item.Quantity === '' || item.UnitAmount === '') {
                submitClickedTemp = true
            }
        })
        if (submitClickedTemp) {
            showSnackbar('Please fill all the fields in the invoice items', 'error')
            return
        }

        const reference = `${patient_id}-${case_id.toString().padStart(2, '0')}`

        if (data.LineItems?.filter(item => item.ItemCode !== "").length > 0) {
            const TOTAL_AMOUNT = LineItems.reduce((acc, item) => {
                return acc + (item.Quantity * item.UnitAmount)
            }, 0)


            // Check if the total amount matches the amount in any of the invoices (For only current reference invoice)
            const AMOUNT_MATCHES = invoices.filter(invoice => invoice.Reference
                === reference).filter(invoice => invoice.InvoiceID !== invoice_id).find(invoice => invoice.Total === TOTAL_AMOUNT + taxAmount)
     
            if (AMOUNT_MATCHES) {
                console.log(AMOUNT_MATCHES)
                setAmountMatches(AMOUNT_MATCHES?.InvoiceID)
                
            }
        }

        if (lastClicked === 'approve' || data.Status === 'AUTHORISED') {
            // Check if DueDate is empty return error
            if (data.DueDate === '' || data.DueDate === null || data.DueDate === undefined) {
                showSnackbar('Please select a due date', 'error')
                return
            }

            data.Status = 'AUTHORISED'
        }
        setProcessing(true)
        data.LineItems = LineItems
        console.log("Invoice id", invoice_id)
        if (invoice_id !== 'new') {
            data.InvoiceID = invoice_id

            instance.patch('/case/xero-invoices', data).then(({ data }) => {
                setProcessing(false)
                showSnackbar('Invoice updated successfully', 'success')
                handleClose()
            }
            ).catch(err => {
                console.log(err)
                showSnackbar(err.response.data, 'error')
                setProcessing(false)
            })
        } else {
            instance.post('/case/xero-invoices', data).then(({ data }) => {
                console.log(data)
                showSnackbar('Invoice initialized and saved successfully', 'success')
                handleClose(data)
                setProcessing(false)
                setInvoiceId(data)

                //navigate(`/patients/${patient_id}/case/${case_id}/invoice/${data}`)
            }
            ).catch(err => {
                console.log(err)
                setProcessing(false)

                showSnackbar(err.response.data, 'error')
            })
        }
    };

    const fetchInvoiceNumber = () => {
        instance.get(`/case/xero-invoices?invoiceNumber=true`).then(({ data }) => {
            console.log(data)
            setInvoiceNumber(data)
            setValue("InvoiceNumber", data)
        }
        ).catch(err => {
        })
    }

    useEffect(() => {
        if (invoice_id === 'new')
            fetchInvoiceNumber()

        setValue("Reference", `${patient_id}-${case_id.toString().padStart(2, '0')}`)
    }, [])

    const INVOICE_NUMBER_ALREADY_EXISTS = invoices.filter(invoice => invoice.InvoiceNumber !== selectedInvoice?.InvoiceNumber).find(invoice => invoice.InvoiceNumber === getValues('InvoiceNumber'))



    const [breadcrumbs_v, setBreadCrumbs] = useState([
        { url: "/patients", text: "Patients" },
        { url: `/patients/${patient_id}`, text: `#${patient_id}` },
        { text: "Cases" },
        { text: `#${case_id.toString().padStart(2, '0')}`, url: `/patients/${patient_id}/case/${case_id}?tab=expenses` },
        { text: `Invoice` },
    ])

    // useEffect(() => {
    //     // If there is an invoiceNumber, set on 4th index
    //     if (watch("InvoiceNumber")) {
    //         setBreadCrumbs([
    //             { url: "/patients", text: "Patients" },
    //             { url: `/patients/${patient_id}`, text: `#${patient_id}` },
    //             { text: "Cases" },
    //             { text: `#${case_id.toString().padStart(2, '0')}`, url: `/patients/${patient_id}/case/${case_id}?tab=xero` },
    //             { text: `${watch("InvoiceNumber")}` },
    //         ])
    //     }
    // }, [watch("InvoiceNumber")])

    const get_authorize_tooltip = () => {
        if (selectedInvoice?.Status === 'AUTHORISED') {
            return "Invoice already authorised"
        } else if (!invoicePaymentData?.created_at) {
            return "Payment details not added"
        } else {
            return "Authorise Invoice"
        }
    }
    const toolTipTitle = get_authorize_tooltip()


    const submitFormManually = () => {
        if (dueDateEmpty) {
            showSnackbar('Please select a due date', 'error')
            return
        }


        setLastClicked("approve")
        setSubmitClicked(true)
        setValue("Status", "AUTHORISED")
        handleSubmit(onSubmit)();
    }

    // gray for DRAFT, orange for AUTHORISED, green for PAID, red for DELETED
    const STATUS_COLORS = {
        DRAFT: 'default',
        AUTHORISED: 'warning',
        PAID: 'success',
        DELETED: 'error'
    }

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <SnackbarComponent />
            <form onSubmit={handleSubmit(onSubmit)} id="invoice-form">
                <BContainer>
                    <BreadCrumbs data={breadcrumbs_v} />

                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, pt: 0.4 }}>
                            

                            {selectedInvoice && ['PAID', 'DELETED'].includes(selectedInvoice.Status) ? null :
                                <>
                                    {selectedInvoice?.status === "AUTHORISED" && !user?.xero_pam ? null :
                                        <>
                                        { invoice_id !== "new" ?
                                            <Button type="submit"
                                                disabled={processing}
                                                onClick={() => setLastClicked('saveAndClose')}
                                                name="action" value="saveAndClose"
                                                size='small'
                                                sx={{ textTransform: 'none' }}
                                                variant='outlined'>Save</Button>
                                            :
                                            <Button type="submit"
                                                disabled={processing}
                                                onClick={() => setLastClicked('saveOnly')}
                                                name="action" value="saveOnly"
                                                size='small'
                                                sx={{ textTransform: 'none' }}
                                                variant='outlined'>Save</Button>
                                        }
                                        </>
                                    }
                                    <AuthorizeInvoiceDialog
                                        processing={processing} setLastClicked={submitFormManually} selectedInvoice={selectedInvoice}
                                        invoicePaymentData={invoicePaymentData}
                                        control={control} errors={errors}
                                        dueDateEmpty={dueDateEmpty}

                                    />

                                    <DeleteInvoice invoice={selectedInvoice} />
                                </>
                            }

                            <IconButton onClick={() => handleClose("close")} variant='outlined' size='small'
                                sx={{ textTransform: 'none' }}
                                disabled={processing}
                            >
                                <CloseIcon />
                            </IconButton>

                        </Box>
                    </Box>
                </BContainer>
                <Box sx={{ px: 2, py: 1 }}>
                    <Card sx={{ border: '1px solid #eee', shadow: 'none' }}>
                        {
                            processing && <LinearProgress />
                        }
                       
                        <Box sx={{ fontSize: '16px', display: 'flex', gap: 1, px: 2, py: 1.5, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Invoice {watch("InvoiceNumber")}
                            </Typography>
                            <Chip label={watch("Status")} color={STATUS_COLORS[watch("Status") || 'DRAFT']}
                                sx={{ mt: 0.6 }}
                                size='small' />
                        </Box>
                        <CardContent>
                            {/* Check if current invoice number alread assigned to any of the invoices */}
                            {INVOICE_NUMBER_ALREADY_EXISTS &&
                                <Alert severity="error" sx={{ mb: 1 }}>
                                    Invoice number already exists
                                </Alert>
                            }

                            {/* Check if the total amount matches the amount in any of the invoices */}
                            {amountMatches &&
                                <Alert severity="error" sx={{ mb: 1 }}>
                                    Invoice with same amount already exists for this case <Button component={Link} to={`/patients/${patient_id}/case/${case_id}/invoice/${amountMatches}`} size="small">View Invoice</Button>
                                </Alert>
                            }

                            <Box sx={{ pb: 2 }}>
                                <InvoiceCreationBasicForm
                                    invoice_id={invoice_id} control={control} register={register} watch={watch} errors={errors} setValue={setValue} getValues={getValues} reset={reset} />
                                <InvoiceItemsTable
                                    LineItems={LineItems} setLineItems={setLineItems}
                                    selectedInvoice={selectedInvoice}
                                    setTaxAmount={setTaxAmount}
                                    fields={fields} append={append} remove={remove}
                                    watch={watch} control={control} register={register}
                                    errors={errors} setValue={setValue} getValues={getValues} reset={reset}
                                    invoice_id={invoice_id}
                                    attachments={attachments} appendAttachments={appendAttachments} removeAttachments={removeAttachments}

                                />
                            </Box>
                          
                        </CardContent>
                    </Card>

                </Box>
            </form>




            <Box sx={{ px: 2, py: 1 }}>
                {invoice_id !== 'new' &&
                    <Box>
                        <PatientCaseInvoiceNotes invoice_status={selectedInvoice?.status} />
                    </Box>
                }
            </Box>
        </AppLayout>
    );
}
