import { Typography } from "@mui/material";
const ActivityColumns = [
    {
        field: "timestamp",
        headerName: 'Date',
        width: 165,
        renderCell: (params) => (
            <Typography variant="body2" color="textPrimary">{params.row.timestamp}</Typography>
        )
    },
    {
        field: "resource",
        headerName: 'Resource',
        width: 140
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 180,
        renderCell: (params) => (
            <Typography variant="body2" color="textPrimary"
                sx={{ textTransform: 'capitalize' }}
            >{params.row.type?.replaceAll("_", " ")?.toLowerCase()
                }</Typography>
        )
    },
    {
        field: 'creator',
        headerName: 'User',
        width: 190,
        renderCell: (params) => (
            <Typography variant="body2" color="textPrimary">{params.row.creator}</Typography>
        )
    },
    {
        field: 'message',
        headerName: 'Message',
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body2" color="textPrimary"
                // handle overflow on next line
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >{params.row.message}</Typography>
        )
    },
]

export default ActivityColumns;