import { Alert, Button, Card, CardActions, CardContent, Skeleton } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DetailsCard from "./DetailsCard";
import CaseTabs from "./Tabs";
import instance from "../../../auth/utils/useJwt";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { fetchCase, setCaseReadonly } from "../../../redux/case/case";
import { useDispatch, useSelector } from "react-redux";

import useSnackbar from "../../../components/Dialogs/SnackBar";
import CaseMenu from "./DropMenu";

import BContainer from "../../../components/BContainer";
import LoadingSkeleton from "./dialogs/LoadingSk";

export default function PatientCaseDetails() {
    const [tab, setTab] = useState(0)
    const [patientData, setPatientData] = useState(null)
    const { data, is_readonly } = useSelector(state => state.patient_case)
    const { patient_id, case_id } = useParams()
    const [apiReq, setApiReq] = useState({ loading: true, error: null })
    const [updating, setUpdating] = useState(false)
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const dispatch = useDispatch()
    const { roles, role_names } = useSelector(state => state.user_roles)
    useEffect(() => {
        instance.get(`/patients?id=${patient_id}&case_id=${case_id}`).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            setPatientData(res.data)
        }).catch(err => {
            setApiReq({ loading: false, error: "Unable to fetch case data" })
            console.log(err)
        })
    }, [])

    const isCaseReadOnly = () => {
        if (role_names.includes("Viewer")) {
            return true
        } else if (role_names.includes("Manager") || role_names.includes("Admin")) {
            return false
        }

        if (role_names?.includes("Case Viewer") || role_names?.includes("Viewer")) {
            // Check if user_roles where name is "Case Viewer" has same region as data.region
            const case_viewer = roles?.find(role => role.name === "Case Viewer")
            dispatch(setCaseReadonly(case_viewer?.region_name === data?.region))
            return case_viewer?.region_name === data?.region
        }
        return false
    }

    useEffect(() => {
        if (patientData) {
            console.log(patientData.id, case_id)
            dispatch(fetchCase({ patient_id: patientData.id, case_id: parseInt(case_id) }))
        }
    }, [patientData])

    const onUpdateClick = () => {
        const update_data = { ...data, patient_id: patientData.id, case_id: parseInt(case_id) }

        //data.patient_id = patientData.id
        //data.case_id = parseInt(case_id)
        setUpdating(true)
        instance.patch("/cases", update_data).then(res => {
            console.log(res)
            setUpdating(false)
            showSnackbar("Case data updated successfully", "success")
        }).catch(err => {
            console.log("Error")
            setUpdating(false)
            showSnackbar("Failed to update case data", "error")
        })
    }


    const breadcrumbs_v = [
        { url: "/patients", text: "Patients" },
        { url: `/patients/${patient_id}`, text: `#${patient_id}` },
        { text: "Cases" },
        { text: `#${case_id.toString().padStart(2, '0')}` },
    ]

    const urlParams = new URLSearchParams(window.location.search);
    const tab_name = urlParams.get('tab') || "acceptance"

    const save_buttons = () => {
        if (is_readonly || isCaseReadOnly()) return null

        // to skip tabs
        const to_skip_tabs = ["expenses", "documents", "memos", "audit", "activity"]

        if (to_skip_tabs.includes(tab_name))
            return null

        return <CardActions sx={{ borderTop: '1px solid #ddd', justifyContent: 'flex-end', py: 1.5 }}>
            <Button color="success" size="small" onClick={onUpdateClick} disabled={updating} variant="contained">{
                updating ? 'Saving Changes...' : 'Save Changes'
            }</Button>
            <Button variant="outlined" size="small" disabled={updating}>Undo</Button>
        </CardActions>
    }


    return (
        <AppLayout title={`Case #${case_id.toString().padStart(2, '0')}`} px={0} apppadding={"0px"}>
            <BContainer>
                {/* <Breadcrumbs separator="|" aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs> */}
                <BreadCrumbs data={breadcrumbs_v} />
                {patientData &&
                    <CaseMenu data={patientData} />}
            </BContainer>

            <SnackbarComponent />

            <Box sx={{ px: 3, py: 1 }}>
                {
                    isCaseReadOnly() ?
                        <Alert severity="info" sx={{ mb: 2 }}>You have view only access to this case.</Alert>
                        : null
                }
                {
                    apiReq.loading ?
                        <Box>
                            <Skeleton variant="rectangular" height={200} />
                            <LoadingSkeleton />
                        </Box>
                        :
                        <Box>
                            {apiReq.error ? <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}>
                                <Typography variant="h6">Case not found</Typography>
                            </Box>
                                :
                                <Box>
                                    <DetailsCard data={patientData} />
                                    <Card sx={{ mt: 3 }}>
                                        {data ?
                                            <CardContent sx={{ p: 2 }}>
                                                <CaseTabs patient_id={patientData.id} setTab={setTab} />
                                            </CardContent>
                                            :
                                            <Box sx={{ p: 2 }}>
                                                <LoadingSkeleton />
                                            </Box>
                                        }


                                        {save_buttons()}


                                    </Card>
                                </Box>}
                        </Box>
                }

            </Box>

        </AppLayout >
    )
}